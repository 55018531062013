export const articles2024 = [
  {
    date: "27/08/2024",
    title:
      "Après l’arrestation de Pavel Dourov, une vague de cyberattaques cible la France",
    url: "https://www.liberation.fr/international/apres-larrestation-de-pavel-durov-une-vague-de-cyberattaques-cible-la-france-20240827_LA6BINIQPREQFCYLCRT3FDZEQM/",
  },
  {
    date: "27/08/2024",
    title: "Identity of Notorious Hacker USDoD Revealed",
    url: "https://www.securityweek.com/true-identity-of-notorious-hacker-usdod-revealed/",
  },
  {
    date: "27/08/2024",
    title:
      "Blonda prezentă alături de șeful Telegram în momentul arestării sale în Franța a aprins imaginația internauților",
    url: "https://adevarul.ro/stiri-externe/europa/blonda-prezenta-alaturi-de-seful-telegram-in-2384236.html",
  },
  {
    date: "29/08/2024",
    title: "The unmasking of threat actor USDoD",
    url: "https://cybernews.com/security/the-unmasking-of-threat-actor-usdod/",
  },
  {
    date: "27/08/2024",
    title: "Researchers unmasked the notorious threat actor USDoD",
    url: "https://securityaffairs.com/167646/cyber-crime/researchers-unmasked-usdod.html",
  },
  {
    date: "28/08/2024",
    title:
      "Cine este femeia misterioasă care se afla lângă şeful Telegram atunci când a fost arestat. Teoriile conspiraţiei au umplut internetul",
    url: "https://www.romaniatv.net/cine-este-femeia-misterioasa-care-se-afla-langa-seful-telegram-atunci-cand-a-fost-arestat-teoriile-conspiratiei-au-umplut-internetul_8478442.html",
  },
  {
    date: "26/08/2024",
    title:
      "Qui est Julia Vavilova, experte en cryptomonnaie et prétendue petite amie de Pavel Durov, le sulfureux fondateur de Telegram",
    url: "https://madame.lefigaro.fr/societe/actu/qui-est-julia-vavilova-experte-en-cryptomonnaie-et-pretendue-petite-amie-de-pavel-durov-le-sulfureux-fondateur-de-telegram-20240826",
  },
  {
    date: "28/08/2024",
    title: "臭名昭著的黑客USDoD身份曝光 ",
    url: "https://www.sohu.com/a/804280931_100092465",
  },
  {
    date: "30/08/2024",
    title:
      "Pavel Durov mis en examen, cryptologie et diplomatie : 10 questions pour tout comprendre de « l’affaire Telegram »",
    url: "https://www.nouvelobs.com/economie/20240830.OBS92987/pavel-durov-mis-en-examen-cryptologie-et-diplomatie-10-questions-pour-tout-comprendre-de-l-affaire-telegram.html",
  },
  {
    date: "03/09/2024",
    title:
      "Pétition de LFI pour la destitution d'Emmanuel Macron : peut-on se fier au compteur qui affiche près de 200 000 signatures ? ",
    url: "https://www.francetvinfo.fr/vrai-ou-fake/vrai-ou-faux-petition-de-lfi-pour-la-destitution-d-emmanuel-macron-peut-on-se-fier-au-compteur-qui-affiche-pres-de-200-000-signatures_6758932.html",
  },
  {
    date: "09/09/2024",
    title:
      "Cybermoi/s 2024 : événement du lancement officiel à l’Assemblée nationale, le 2 octobre",
    url: "https://www.cybermalveillance.gouv.fr/tous-nos-contenus/actualites/cybermois-2024-evenement",
  },
  {
    date: "18/09/2024",
    title:
      "September 17, 2024 - Hezbollah vows retaliation against Israel for deadly pager explosions ",
    url: "https://edition.cnn.com/world/live-news/lebanon-pagers-attack-hezbollah/index.html",
  },
  {
    date: "18/09/2024",
    title:
      "These Clues Explain the Hezbollah Pagers Explosions, Security Experts Say",
    url: "https://www.barrons.com/articles/this-is-sophisticated-the-hezbollah-pager-explosions-explained-476c64e3",
  },
  {
    date: "18/09/2024",
    title:
      "We still don’t know how the Lebanon pager attack happened. Here’s what we do know about our own electronic devices",
    url: "https://edition.cnn.com/2024/09/17/business/pagers-cell-phones-batteries/index.html",
  },

  {
    date: "28/09/2024",
    title:
      "Boulanger, Cultura, SFR… Vos données ont été volées ? Voici ce que vous risquez et comment vous protéger",
    url: "https://www.ladepeche.fr/2024/09/28/boulanger-cultura-sfr-vos-donnees-ont-ete-volees-voici-ce-que-vous-risquez-et-comment-vous-proteger-12225367.php",
  },
  {
    date: "30/09/2024",
    title: "追跡 中国・流出文書 3 ～ハッカー企業の素顔～",
    url: "https://www3.nhk.or.jp/news/html/20240930/k10014588411000.html",
  },
  {
    date: "28/10/2024",
    title:
      "« Cybermoi/s 2024 : évènement officiel de lancement à l'Assemblée nationale",
    url: "https://www.youtube.com/watch?v=NXk12BgF1Ls",
  },
  {
    date: "16/10/2024",
    title: "Brazil’s Polícia Federal arrested the notorious hacker USDoD",
    url: "https://securityaffairs.com/169914/cyber-crime/brazils-policia-federal-arrested-hacker-usdod.html",
  },
  {
    date: "20/10/2024",
    title:
      "UNLOCK #8 : Futur d'Apple, Snapdragon 8 Elite, faux arrêts de travail, hacking éthique, rumeurs S25",
    url: "https://www.youtube.com/watch?v=l4U4a05RNB8",
  },
  {
    date: "28/10/2024",
    title:
      "Table Ronde : « Cybersécurité et résilience : comment se préparer à la prochaine attaque ? »",
    url: "https://www.youtube.com/watch?v=KVUPyErsGpE",
  },
  {
    date: "28/10/2024",
    title:
      "« C’est quoi un hacker ? Démonstrations et autres tracasseries cyber »",
    url: "https://www.youtube.com/watch?v=UaTQg_4oT6M",
  },
  {
    date: "31/10/2024",
    title:
      "Surveillance des recherches Google : « Les gens fantasment l'utilisation de l'IA par le gouvernement »",
    url: "https://www.marianne.net/societe/surveillance-des-recherches-google-les-gens-fantasment-l-utilisation-de-l-ia-par-le-gouvernement",
  },
  {
    date: "01/11/2024",
    title: "Hacked:Unmasking a Cybercriminal With Open Source Intelligence",
    url: "https://podcastaddict.com/hacked/episode/185333388",
  },
  {
    date: "04/11/2024",
    title: "“RedLine” – Infostealer",
    url: "https://ciberprisma.org/2024/11/04/redline-infostealer/",
  },
  {
    date: "21/11/2024",
    title:
      "« Il faut absolument que je te montre un truc » : les comptes de plusieurs députés piratés sur Telegram",
    url: "https://www.rtl.fr/actu/sciences-tech/il-faut-absolument-que-je-te-montre-un-truc-les-comptes-de-plusieurs-deputes-pirates-sur-telegram-7900443050",
  },
  {
    date: "22/11/2024",
    title:
      "Parlamentari Francesi Hackerati! Un attacco Banalissimo di Phishing Li Colpisce in Massa",
    url: "https://www.redhotcyber.com/post/parlamentari-francesi-hackerati-un-attacco-banalissimo-di-phishing-li-colpisce-in-massa/",
  },
  {
    date: "10/06/2024",
    title:
      "Spécialiste de l'OSINT, Baptiste ROBERT te donne des conseils pour démarrer ",
    url: "https://www.youtube.com/watch?v=91T8CM77C_M",
  },
  {
    date: "19/07/2024",
    title:
      "Panne informatique mondiale : quelle est la cause des perturbations chez Microsoft ? ",
    url: "https://www.numerama.com/cyberguerre/1777992-panne-informatique-mondiale-quelle-est-la-probable-cause-des-perturbations-de-microsoft.html",
  },
  {
    date: "19/07/2024",
    title:
      "Analyse de la panne informatique mondiale avec un spécialiste de la cybersécurité",
    url: "https://france3-regions.francetvinfo.fr/occitanie/haute-garonne/toulouse/c-est-comme-un-chateau-de-cartes-tout-s-effondre-analyse-de-la-panne-informatique-mondiale-avec-un-specialiste-de-la-cybersecurite-3007097.html",
  },
  {
    date: "20/07/2024",
    title:
      "Crowdstrike, Facebook, Amazon... Ces gigantesques pannes qui ont mis internet à terre",
    url: "https://www.bfmtv.com/tech/vie-numerique/crowdstrike-facebook-amazon-ces-gigantesques-pannes-qui-ont-mis-internet-a-terre_AV-202407200054.html",
  },
  {
    date: "20/07/2024",
    title:
      "How long could the consequences of the bug caused by CrowdStrike last?",
    url: "https://actualnewsmagazine.com/english/how-long-could-the-consequences-of-the-bug-caused-by-crowdstrike-last/",
  },
  {
    date: "29/07/2024",
    title:
      "Attaque sur le réseau SNCF, qui se cache derrière cette attaque massive ? ",
    url: "https://www.sudradio.fr/emission/les-grands-debats-du-matin-460",
  },
  {
    date: "17/09/2024",
    title:
      "Liban : ces centaines de bipeurs ont explosé en même temps dans les poches des membres du Hezbollah ",
    url: "https://www.numerama.com/cyberguerre/1809512-liban-ces-centaines-de-bipeurs-ont-explose-en-meme-temps-dans-les-poches-des-membres-du-hezbollah.html",
  },
  {
    date: "17/09/2024",
    title:
      "Explosion contre le Hezbollah au Liban : est-il possible de pirater un bipeur ? ",
    url: "https://www.numerama.com/cyberguerre/1809804-explosion-contre-le-hezbollah-au-liban-est-ce-quil-est-possible-de-pirater-un-bipeur.html",
  },
  {
    date: "17/09/2024",
    title:
      "Au Liban, une surprenante publicité de l’armée américaine apparaît sur Tinder",
    url: "https://www.ladn.eu/tech-a-suivre/pourquoi-les-utilisateurs-libanais-de-tinder-ont-ete-menace-par-larmee/",
  },
  {
    date: "18/09/2024",
    title:
      "Explosions de bipeurs du Hezbollah: pourquoi la piste d'un piratage à distance est peu crédible",
    url: "https://www.bfmtv.com/tech/actualites/cybersecurite/explosions-de-bipeurs-du-hezbollah-pourquoi-la-piste-d-un-piratage-a-distance-est-peu-credible_AV-202409180374.html",
  },
  {
    date: "16/05/2024",
    title: "Les As de la cybersécurité 2024",
    url: "https://guardia.school/le-lab/les-as-de-la-cybersecurite-2024.html",
  },
  {
    date: "26/05/2024",
    title:
      "Baptiste Robert, Predicta Lab : « Attention à votre empreinte numérique ! »",
    url: "https://www.touleco.tv/Baptiste-Robert-Predicta-Lab-Attention-a-votre-empreinte,38188",
  },
  {
    date: "30/05/2024",
    title:
      "«Prise d'otage numérique» : une enquête ouverte sur une potentielle cyberattaque massive du site Ticketmaster",
    url: "https://www.radiofrance.fr/franceinter/podcasts/l-info-de-france-inter/l-info-de-france-inter-4332668",
  },
  {
    date: "10/05/2024",
    title: "Un Toulousain dévoile la vie privée du plus grand hacker russe",
    url: "https://www.sudradio.fr/societe/un-toulousain-devoile-la-vie-privee-du-plus-grand-hacker-russe",
  },

  {
    date: "08/05/2024",
    title:
      "Le nom du hacker le plus recherché du monde est désormais connu: interview de Baptiste Robert",
    url: "https://www.rts.ch/play/tv/forum/video/le-nom-du-hacker-le-plus-recherche-du-monde-est-desormais-connu-interview-de-baptiste-robert?urn=urn:rts:video:14892113",
  },

  {
    date: "07/05/2024",
    title:
      "Des infos privées sur la vie du chef des pirates de Lockbit déjà révélées par un hacker éthique",
    url: "https://www.numerama.com/cyberguerre/1740648-des-infos-privees-sur-la-vie-du-chef-des-pirates-de-lockbit-deja-revelees-par-un-hacker-ethique.html",
  },

  {
    date: "09/05/2024",
    title:
      '"Il a volé plus de 100 millions de dollars…" Comment le hacker russe le plus recherché au monde a été débusqué par le Toulousain Baptiste Robert',
    url: "https://www.ladepeche.fr/2024/05/09/il-a-vole-plus-de-100-millions-de-dollars-comment-le-hacker-le-plus-recherche-au-monde-a-ete-debusque-par-le-toulousain-baptiste-robert-11938458.php",
  },

  {
    date: "09/05/2024",
    title:
      "Cybercriminalité : l'identité du meneur de Lockbit dévoilée, Baptiste Robert le 'hackeur éthique' décrypte sa vie pour le grand public",
    url: "https://france3-regions.francetvinfo.fr/occitanie/haute-garonne/toulouse/cybercriminalite-l-identite-du-meneur-de-lockbit-devoilee-baptiste-robert-le-hackeur-ethique-decrypte-sa-vie-pour-le-grand-public-2967290.html",
  },

  {
    date: "07/05/2024",
    title: "Comment le groupe de pirates Lockbit est revenu en force",
    url: "https://www.bfmtv.com/tech/cybersecurite/ils-n-ont-jamais-disparu-comment-le-groupe-lockbit-est-revenu-en-force-apres-l-operation-chronos_GN-202405070024.html",
  },

  {
    date: "09/05/2024",
    title:
      "Cyberattaque : qui est Dmitri Khoroshev, le patron russe du groupe de hackers russes Lockbit ?",
    url: "https://www.sudouest.fr/economie/cybersecurite/cyberattaque-qui-est-dmitri-khoroshev-le-patron-russe-du-groupe-de-hackers-russes-lockbit-19639429.php",
  },

  {
    date: "07/05/2024",
    title:
      "LockBit : l’opération Cronos montre que le ransomware ne paie pas pour beaucoup de cybercriminels",
    url: "https://www.lemagit.fr/actualites/366583792/LockBit-loperation-Cronos-montre-que-le-ransomware-ne-paie-pour-beaucoup-de-cybercriminels",
  },

  {
    date: "08/05/2024",
    title:
      "Voici le visage du cybercriminel le plus recherché de la planète, leader du groupe LockBit",
    url: "https://www.clubic.com/actualite-526359-voici-le-visage-du-cybercriminel-le-plus-recherche-de-la-planete-leader-du-groupe-lockbit.html",
  },

  {
    date: "10/05/2024",
    title: "Un Toulousain débusque un des plus grands hackeurs russes",
    url: "https://www.youtube.com/watch?v=CV11Hqubxd8&ab_channel=SudRadio",
  },

  {
    date: "08/05/2024",
    title:
      "Qui est Dmitri Khoroshev, le patron russe du groupe de hackers Lockbit recherché par Washington ?",
    url: "https://www.leparisien.fr/high-tech/qui-est-dmitri-khoroshev-le-patron-russe-du-groupe-de-hackers-lockbit-recherche-par-washington-08-05-2024-J4W2M4NXXFF2ZHXX4NPI2SN7AU.php",
  },

  {
    date: "10/05/2024",
    title:
      "Piratages de centrales électriques, vols de données confidentielles… le groupe cybercriminel Sandworm sème le chaos au service de la Russie",
    url: "https://www.ladepeche.fr/2024/05/10/piratages-de-centrales-electriques-vols-de-donnees-confidentielles-le-groupe-cybercriminel-sandworm-seme-le-chaos-au-service-de-la-russie-11941864.php",
  },

  {
    date: "08/05/2024",
    title: "Qui est Dmitry Khoroshev, le leader du groupe de hacker LockBit ?",
    url: "https://www.tf1.fr/tf1/top-info/videos/qui-est-dmitry-khoroshev-le-leader-du-groupe-de-hacker-lockbit-39337237.html",
  },

  {
    date: "08/05/2024",
    title: "Ransomware : le leader de Lockbit a été démasqué ",
    url: "https://www.01net.com/actualites/ransomware-leader-lockbit-demasque.html",
  },
  {
    date: "17/01/2024",
    title: "Pendant les JO 2024, nous serons une cible pour les cyberattaques",
    url: "https://toulouse.latribune.fr/entreprises/business/2024-01-17/pendant-les-jo-2024-nous-serons-une-cible-pour-les-cyberattaques-baptiste-robert-predicta-lab-988007.html",
  },
  {
    date: "12/01/2024",
    title:
      "La cybersécurité ne doit pas être perçue comme un poste de dépenses par les entreprises",
    url: "https://toulouse.latribune.fr/entreprises/2024-01-12/la-cybersecurite-ne-doit-pas-etre-percue-comme-un-poste-de-depenses-par-les-entreprises-baptiste-robert-987735.html",
  },
  {
    date: "16/01/2024",
    title: "Cybersécurité : l'Occitanie se dote d'un Campus Cyber",
    url: "https://toulouse.latribune.fr/entreprises/2024-01-16/cybersecurite-l-occitanie-se-dote-d-un-campus-cyber-987965.html",
  },
  {
    date: "13/03/2024",
    title:
      "Sous une nouvelle présidence, le Toulouse Olympique XIII veut lancer un nouveau cycle",
    url: "https://www.touleco.fr/Sous-une-nouvelle-presidence-le-Toulouse-Olympique-XIII-veut,41442",
  },
  {
    date: "22/02/2024",
    title:
      "A quoi ressemblent les hackers de LockBit, le groupe de pirates dont tout le monde parle ?",
    url: "https://www.numerama.com/cyberguerre/1635544-a-quoi-ressemblent-les-hackers-de-lockbit-le-groupe-de-pirates-dont-tout-le-monde-parle.html",
  },
  {
    date: "23/02/2024",
    title: "JO de Paris 2024 : Plus de 4 milliards de cyberattaques prévues !!",
    url: "https://jadacomconsulting.com/2024/02/23/jo-de-paris-2024-plus-de-4-milliards-de-cyberattaques-prevues/",
  },
  {
    date: "14/03/2024",
    title:
      "Cyberattaque de France Travail : la menace principale, c'est l'usurpation d'identité",
    url: "https://www.liberation.fr/economie/economie-numerique/cyberattaque-de-france-travail-la-menace-principale-cest-lusurpation-didentite-20240314_34PR7OWQ7RG2XLK34L35N6CAEM/",
  },
  {
    date: "16/02/2024",
    title: "Nos données de santé sont-elles en danger ?",
    url: "https://www.bfmtv.com/tech/replay-emissions/metadonnees/nos-donnees-de-sante-sont-elles-en-danger_VN-202402160539.html",
  },
  {
    date: "05/04/2024",
    title:
      '"Aider les communes à trouver des solutions concrètes" : le premier CyberTour rencontre un franc succès dans le Gers',
    url: "https://www.ladepeche.fr/2024/04/05aider-les-communes-a-trouver-des-solutions-concretes-le-premier-cybertour-rencontre-un-franc-succes-dans-le-gers-11873191.php",
  },
  {
    date: "20/02/2024",
    title:
      "Piratage : EDF frappée, les données d’un demi-million de Français volées ?",
    url: "https://www.economiematin.fr/cyberattaque-piratage-edf-500-000-abonnes-202",
  },
];

export const articles2023 = [
  {
    date: "01/01/2023",
    title: "Predicta Lab derrière le TO en 2023 !",
    url: "https://www.to13.com/actualites/predicta-lab-derriere-le-to-en-2023/",
  },
  {
    date: "09/01/2023",
    title: "Corexalys, nouvelle cyber-vigie du ministère des armées",
    url: "https://www.intelligenceonline.fr/renseignement-d-affaires/2023/01/09/corexalys-nouvelle-cyber-vigie-du-ministere-des-armees,109879560-art",
  },
  {
    date: "19/02/2023",
    title: "OSINT: La guerre en Ukraine enflamme les espions du dimanche",
    url: "https://www.heidi.news/cyber/osint-la-guerre-en-ukraine-enflamme-les-espions-du-dimanche",
  },
  {
    date: "28/02/2023",
    title: "ENQUÊTE. Comment votre téléphone vous espionne",
    url: "https://www.lejdd.fr/societe/enquete-comment-votre-telephone-vous-espionne-133103",
  },
  {
    date: "01/03/2023",
    title:
      "Fichés par nos téléphones, ou comment toutes nos connexions nous trahissent",
    url: "https://www.lejdd.fr/societe/fiches-par-nos-telephones-ou-comment-toutes-nos-connexions-nous-trahissent-133148",
  },
  {
    date: "13/04/2023",
    title: "Predicta Lab, le spécialiste du renseignement en source ouverte",
    url: "https://www.touleco.fr/Predicta-Lab-le-specialiste-du-renseignement-en-source-ouverte,37596",
  },
  {
    date: "26/05/2023",
    title:
      "Baptiste Robert, Predicta Lab : « Attention à votre empreinte numérique ! »",
    url: "https://www.touleco.tv/Baptiste-Robert-Predicta-Lab-Attention-a-votre-empreinte,38188",
  },
  {
    date: "07/07/2023",
    title:
      "Comment les enquêteurs tentent de remonter jusqu'aux émeutiers depuis Snapchat et TikTok",
    url: "https://www.parismatch.com/actu/faits-divers/comment-les-enqueteurs-tentent-de-remonter-jusquaux-emeutiers-depuis-snapchat-et-tiktok-227086",
  },
  {
    date: "07/07/2023",
    title:
      "Emeutes : les cyberenquêteurs investissent les réseaux sociaux pour traquer les violences",
    url: "https://www.bfmtv.com/tech/actualites/reseaux-sociaux/emeutes-les-cyberenqueteurs-investissent-les-reseaux-sociaux-pour-traquer-les-violences_AD-202307070191.html",
  },
  {
    date: "20/07/2023",
    title:
      "Hacking éthique et cybersecurité : l'empreinte numérique en question",
    url: "https://www.archimag.com/vie-numerique/2023/07/20/hacking-ethique-cybersecurite-empreinte-numerique-rencontre-baptiste-robert",
  },
  {
    date: "06/09/2023",
    title:
      "Cartes bancaires, codes Netflix, passeports sur le darknet : un supermarché des données volées",
    url: "https://www.lexpress.fr/societe/cartes-bancaires-codes-netflix-passeports-sur-le-darknet-un-supermarche-des-donnees-volees-VHAP2JPUURBEJOK67QQKHR4Z6E/",
  },
  {
    date: "19/09/2023",
    title:
      "Vrai ou faux : les « community notes » du réseau social X luttent-elles efficacement contre la désinformation ?",
    url: "https://www.francetvinfo.fr/vrai-ou-fake/vrai-ou-faux-les-community-notes-du-reseau-social-x-luttent-elles-efficacement-contre-la-desinformation_6051188.html",
  },
  {
    date: "21/09/2023",
    title:
      "Le problème, quand on ne maîtrise pas son sujet, c'est que cela se voit : comment un hacker éthique passe au crible la loi sur Internet",
    url: "https://france3-regions.francetvinfo.fr/occitanie/haute-garonne/toulouse/le-probleme-quand-on-ne-maitrise-pas-son-sujet-c-est-que-cela-se-voit-comment-un-hacker-ethique-passe-au-crible-la-loi-sur-internet-2842799.html",
  },
  {
    date: "01/11/2023",
    title: "What hackers can do with Aadhaar number: Lessons from old tweets",
    url: "https://www.siasat.com/what-hackers-can-do-with-aadhar-number-lessons-from-old-tweets-2788817/",
  },
  {
    date: "26/11/2023",
    title:
      "Portrait : Baptiste Robert, le pirate informatique qui a forcé les plateformes numériques les plus sécurisées du monde pour faire le bien",
    url: "https://www.ladepeche.fr/2023/11/26/portrait-baptiste-robert-le-pirate-informatique-qui-a-force-les-plateformes-numeriques-les-plus-securisees-du-monde-pour-faire-le-bien-11601081.php",
  },
  {
    date: "29/11/2023",
    title:
      "Les ministères sommés de communiquer désormais via l'app française Olvid",
    url: "https://www.ouest-france.fr/politique/les-ministeres-sommes-de-communiquer-desormais-via-lapp-francaise-olvid-e09e7316-5dd5-4449-a052-d28760b1f749",
  },
  {
    date: "30/11/2023",
    title:
      "Qu'est-ce qu'Olvid, l'application que le gouvernement va devoir utiliser pour remplacer WhatsApp et Telegram ?",
    url: "https://www.laprovence.com/article/politique/57580789366745/quest-ce-quolvid-l-application-que-le-gouvernement-va-devoir-utiliser-pour-remplacer-whatsapp-et-telegram",
  },
  {
    date: "30/11/2023",
    title:
      "Qu'est-ce qu'Olvid, la nouvelle messagerie que doivent utiliser les ministères français ?",
    url: "https://www.liberation.fr/economie/economie-numerique/quest-ce-que-olvid-la-nouvelle-messagerie-que-doivent-utiliser-les-ministeres-francais-20231130_CYMTLQZSRRBAXKSMM2LPW2PROQ/",
  },
  {
    date: "30/11/2023",
    title:
      "Le gouvernement français va abandonner WhatsApp au profit d'une application française",
    url: "https://fr.euronews.com/next/2023/11/30/le-gouvernement-francais-va-abandonner-whatsapp-au-profit-dune-application-francaise",
  },
  {
    date: "05/12/2023",
    title: "Les arguments de Matignon pour installer Olvid font tousser Signal",
    url: "https://www.zdnet.fr/actualites/les-arguments-de-matignon-pour-installer-olvid-font-tousser-signal-39962764.htm",
  },
  {
    date: "09/12/2023",
    title: "Un ado tué pour un jogging à Valenton : que s'est-il passé ?",
    url: "https://www.bfmtv.com/replay-emissions/week-end-3-d/un-ado-tue-pour-un-jogging-a-valenton-que-s-est-il-passe-09-12_VN-202312090368.html",
  },
  {
    date: "10/12/2023",
    title:
      "Ado tué à Valenton : comment vendre en ligne entre particuliers en toute sécurité ?",
    url: "https://www.bfmtv.com/societe/ado-tue-a-valenton-comment-vendre-en-ligne-entre-particuliers-en-toute-securite_AV-202312100196.html",
  },
  {
    date: "18/12/2023",
    title:
      "Comment les hackers ont-ils pu pirater le site de la mairie de Paris ?",
    url: "https://m.facebook.com/bsmarttv/videos/identit%C3%A9-num%C3%A9rique-fran%C3%A7aise-quels-enjeux-sil-est-d%C3%A9sormais-possible-de-d%C3%A9mat%C3%A9ri/1064119511451011/?locale=hi_IN  ",
  },
];

export const articles2022 = [
  {
    date: "25/02/2022",
    title:
      "Guerre en Ukraine : comment les faux grossiers de la propagande russe ont été révélés",
    url: "https://www.numerama.com/cyberguerre/866957-guerre-en-ukraine-comment-les-faux-grossiers-de-la-propagande-russe-ont-ete-reveles.html",
  },
  {
    date: "06/03/2022",
    title: "Entre l’Ukraine et la Russie, la guerre de l’information fait rage",
    url: "https://www.sudouest.fr/international/europe/ukraine/entre-l-ukraine-et-la-russie-la-guerre-de-l-information-fait-rage-9538713.php",
  },
  {
    date: "22/04/2022",
    title:
      "« Je fais de la cybersécurité pour les gens » : revendique Baptiste Robert, hacker éthique",
    url: "https://www.20minutes.fr/high-tech/3274251-20220422-fais-cybersecurite-gens-revendique-baptiste-robert-hacker-ethique",
  },
  {
    date: "05/06/2022",
    title: "Comment TikTok a conquis le monde et pourquoi c'est inquiétant",
    url: "https://www.nouvelobs.com/economie/20220605.OBS59319/comment-tiktok-a-conquis-le-monde-et-pourquoi-c-est-inquietant.html",
  },
  {
    date: "13/06/2022",
    title: "Chez Predicta Lab, le hacking éthique au service de la sécurité",
    url: "https://www.touleco.fr/Chez-Predicta-Lab-le-hacking-ethique-au-service-de-la-securite,34545",
  },
  {
    date: "19/06/2022",
    title: "Predicta Lab, un outil pour contrôler son empreinte numérique",
    url: "https://www.airzen.fr/predicta-lab-un-outil-pour-controler-son-empreinte-numerique/",
  },
  {
    date: "23/06/2022",
    title:
      "La course aux plateformes d'OSINT dans l'Hexagone portée par la lutte contre la désinformation",
    url: "https://www.intelligenceonline.fr/surveillance--interception/2022/06/23/la-course-aux-plateformes-d-osint-dans-l-hexagone-portee-par-la-lutte-contre-la-desinformation,109793939-eve",
  },
  {
    date: "26/06/2022",
    title:
      "Guerre en Ukraine : assiste-t-on à l'avènement de l'OSINT, une enquête en ligne accessible à tous ?",
    url: "https://www.20minutes.fr/high-tech/3313419-20220626-guerre-ukraine-assiste-avenement-osint-enquete-ligne-accessible-tous",
  },
];

export const articles2021 = [
  {
    date: "19/03/2021",
    title: "Reflechissez avant de donner vos données",
    url: "https://www.capital.fr/entreprises-marches/reflechissez-avant-de-donner-vos-donnees-1397615",
  },
  {
    date: "15/11/2021",
    title:
      "Predicta Lab, le nouvel outil de Predicta Lab pour lutter contre les fake news",
    url: "https://www.tableovale.fr/actualites/nouveaux-compagnons/2021/baptiste-robert-thierry-revelin-predicta-lab/",
  },
];

export const articles2020 = [
  {
    date: "21/10/2020",
    title: "TikTok battles to stay ‘apolitical’ ahead of US election",
    url: "https://www.ft.com/content/ebffedde-8432-41a3-94ae-72289ef82ba5",
  },
  {
    date: "27/10/2020",
    title:
      "Un hacker toulousain lance Predicta Lab pour lutter contre les fake news et la haine en ligne",
    url: "https://toulouse.latribune.fr/innovation/start-up/2020-10-27/un-hacker-toulousain-lance-predicta-lab-pour-lutter-contre-les-fake-news-et-la-haine-en-ligne-860484.html",
  },
  {
    date: "07/11/2020",
    title:
      "Predicta Lab, une intelligence artificielle contre la haine en ligne",
    url: "https://www.francetvinfo.fr/replay-radio/nouveau-monde/nouveau-monde-une-intelligence-artificielle-contre-la-haine-en-ligne_4154805.html  ",
  },
];
