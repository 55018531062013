import React, { useState } from "react";
import { Link } from "react-router-dom";
import Logo from "../../elements/logo/Logo";
import footer from "../../data/footer/footer.json";
import ScrollTop from "./ScrollTop";
import { FiInstagram, FiLinkedin } from "react-icons/fi";
import { FaXTwitter } from "react-icons/fa6";
import { useTranslation } from "react-i18next";
import CookieConsent from "../../components/cookie/CookieConsent";
import { useTurnstile } from "react-turnstile";

const main = footer[0];
const itemOne = footer[1];
const itemTwo = footer[2];
const newsletter = footer[3];
const indexOneLink = itemOne.quicklink;
const indexTwoLink = itemTwo.quicklink;

const Footer = () => {
  const { t } = useTranslation("Footer");
  const initialEmailInfos = {
    email: "",
    message: { type: "success", text: "" },
  };
  const [emailInfos, setEmailInfos] = useState(initialEmailInfos);
  const turnstile = useTurnstile();

  const preHandleSearch = (token) => {
    if (!emailInfos.email) return;

    async function action() {
      const response = await fetch(
        "https://newsletter.predictalab.fr/newsletter/sub",
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            email_form: { email: emailInfos.email },
            captcha: token,
          }),
        }
      );

      if (!response.ok) {
        throw new Error(`${response.status} - ${response.statusText}`);
      }

      // Reset token
      turnstile.reset();
      return await response.json();
    }

    action()
      .then(() => {
        setEmailInfos({
          email: "",
          message: { type: "success", text: t("Successful subscription") },
        });
      })
      .catch((er) => {
        setEmailInfos({ email: "", message: { type: "error", text: er } });
      });
  };

  return (
    <>
      <footer className="rn-footer footer-style-default">
        <div className="footer-top">
          <div className="container">
            <div className="row">
              {/* Start Single Widget  */}
              <div className="col-lg-4 col-md-6 col-sm-6 col-12 mb-5 mb-md-0">
                <div className="rn-footer-widget">
                  <div className="logo">
                    <Logo
                      image={`${process.env.PUBLIC_URL}/images/logo/predictalab.png`}
                    />
                  </div>

                  <h3 className="text-big">
                    {t(main.title, { ns: "Homepage" })}
                  </h3>

                  <ul className="social-icon social-default with-gradient text-left justify-content-start opacity-75">
                    <li>
                      <a
                        href="https://twitter.com/PredictaLabOff"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <FaXTwitter />
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://www.linkedin.com/company/predicta-lab"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <FiLinkedin />
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://www.instagram.com/predictalab"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <FiInstagram />
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
              {/* End Single Widget  */}

              {/* Start Single Widget  */}
              <div className="col-lg-2 col-md-6 col-sm-6 col-12">
                <div className="rn-footer-widget">
                  <div className="widget-menu-top">
                    <h4 className="title">{t(itemOne.title, { ns: "Nav" })}</h4>
                    <div className="inner">
                      <ul className="footer-link link-hover">
                        {indexOneLink.map((data) => (
                          <li key={data.url}>
                            <Link to={`${data.url}`}>
                              {t(data.text, { ns: "Nav" })}
                            </Link>
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              {/* End Single Widget  */}

              {/* Start Single Widget  */}
              <div className="col-lg-2 col-md-6 col-sm-6 col-12">
                <div className="rn-footer-widget">
                  <div className="widget-menu-top">
                    <h4 className="title">{t(itemTwo.title, { ns: "Nav" })}</h4>
                    <div className="inner">
                      <ul className="footer-link link-hover">
                        {indexTwoLink.map((data) => (
                          <li key={data.url}>
                            <Link to={`${data.url}`}>
                              {t(data.text, { ns: "Nav" })}
                            </Link>
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              {/* End Single Widget  */}

              {/* Start Single Widget  */}
              <div className="col-lg-4 col-md-6 col-sm-6 col-12">
                <div className="rn-footer-widget">
                  <h4 className="title">{t(newsletter.title)}</h4>
                  <div className="inner">
                    <h6 className="subtitle">{t(newsletter.subtitle)}</h6>

                    <form
                      className="newsletter-form"
                      onSubmit={(e) => {
                        e.preventDefault();
                        preHandleSearch(turnstile.getResponse());
                      }}
                    >
                      <div>
                        <input
                          type="email"
                          placeholder={t("Enter Your Email Here")}
                          className="w-100"
                          name="email"
                          required
                          value={emailInfos.email}
                          onChange={(e) =>
                            setEmailInfos((prev) => ({
                              ...prev,
                              email: e.target.value,
                            }))
                          }
                        />
                      </div>
                      <div>
                        <button className="btn-default" type="submit">
                          {t("Subscribe")}
                        </button>
                      </div>
                    </form>

                    <div className="pl--10">
                      <small>
                        {emailInfos.message.type === "success" ? (
                          <span className="text-success">
                            {t(emailInfos.message.text)}
                          </span>
                        ) : (
                          <span className="text-danger">
                            {t(emailInfos.message.text)}
                          </span>
                        )}
                      </small>
                    </div>
                  </div>
                </div>
              </div>
              {/* End Single Widget  */}
            </div>
          </div>
        </div>
      </footer>
      <CookieConsent />
      <ScrollTop />
    </>
  );
};

export default Footer;
