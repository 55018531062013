import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import PageScrollTop from "./components/pageToTop/PageScrollTop";
import Turnstile from "react-turnstile";

// Pages import Here
import Homepage from "./pages/Homepage";
import Error from "./pages/Error";
import Services from "./pages/Services";
import Products from "./pages/Products";
import LegalNotice from "./pages/LegalNotice";
import DataProtectionPolicy from "./pages/DataProtectionPolicy";

// Elements import Here
import Contact from "./elements/contact/Contact";

// Import Css Here
import "./assets/scss/style.scss";

// Translation
import i18next from "i18next";
import { initReactI18next } from "react-i18next";
import fr from "./locales/fr.json";
import es from "./locales/es.json";
import WhitePaper from "./pages/WhitePaper";
import Press from "./pages/Press";

const App = () => {
  // Translation : https://react.i18next.com/
  const language = localStorage.getItem("lng");
  i18next.use(initReactI18next).init({
    resources: { fr, es },
    lng: language ?? "en",
    fallbackLng: "en",
    //debug: true, // Uncomment to debug
  });

  return (
    <>
      <Router>
        <PageScrollTop>
          <Switch>
            <Route
              path={`${process.env.PUBLIC_URL + "/"}`}
              exact
              component={Homepage}
            />
            <Route
              path={`${process.env.PUBLIC_URL + "/products"}`}
              exact
              component={Products}
            />
            <Route
              path={`${process.env.PUBLIC_URL + "/services"}`}
              exact
              component={Services}
            />
            <Route
              path={`${process.env.PUBLIC_URL + "/contact"}`}
              exact
              component={Contact}
            />
            <Route
              path={`${process.env.PUBLIC_URL + "/white-paper"}`}
              exact
              component={WhitePaper}
            />
            <Route
              path={`${process.env.PUBLIC_URL + "/legal-notice"}`}
              exact
              component={LegalNotice}
            />
            <Route
              path={`${process.env.PUBLIC_URL + "/data-protection-policy"}`}
              exact
              component={DataProtectionPolicy}
            />
            <Route
              path={`${process.env.PUBLIC_URL + "/press"}`}
              exact
              component={Press}
            />
            <Route
              path={`${process.env.PUBLIC_URL + "/error"}`}
              exact
              component={Error}
            />
          </Switch>
        </PageScrollTop>
      </Router>
      <Turnstile sitekey="0x4AAAAAAA28hV3wBmVLgXs-" refreshExpired="auto" />
    </>
  );
};

export default App;
