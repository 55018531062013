import styles from "./WhitePaper.module.scss";
import Seo from "../common/SEO";
import Layout from "../common/Layout";
import { Trans, useTranslation } from "react-i18next";
import WhitePaperForm from "../elements/whitepaper/WhitePaperForm";
import Breadcrumb from "../elements/breadcrumb/Breadcrumb";
import { useState } from "react";
import { Link } from "react-router-dom/cjs/react-router-dom.min";

const WhitePaper = () => {
  const { t } = useTranslation("WhitePaper");
  const [sent, setSent] = useState(false);

  const handleDownloadPDF = () => {
    // Download the white paper PDF
    const pdf = "./images/whitepaper/whitepaper.pdf";
    window.open(pdf);
  };
  return (
    <>
      <Seo
        title="
        White Paper - Predicta Search is a powerful tool that allows you to get the digital footprint from an email or phone number. You can get the social media profiles, websites, and more from a person or company.
      "
      />
      <Layout>
        <Breadcrumb
          title={t("White paper")}
          rootUrl="/"
          parentUrl={t("Home", { ns: "Common" })}
          currentUrl={t("White paper")}
        />
        <div className="main-content">
          <div className="container">
            <div className="row">
              <div className="col">
                <div className={styles.wrapper}>
                  <div className={styles.left}>
                    <img
                      src="./images/whitepaper/cover.jpg"
                      alt="White paper cover"
                    />
                    {/* https://mockupbro.com/mockup/hardcover-book-mockup */}
                  </div>
                  <div className={styles.right}>
                    {sent ? (
                      <div className={styles.sent}>
                        <h2>{t("Thank you")}</h2>
                        <p>
                          {t(
                            "We hope you enjoy our white paper. If you have any questions, feel free to"
                          )}
                          <Link to="/contact">{t(" contact us")}</Link>.
                        </p>
                        <button
                          className="btn-default btn-large"
                          onClick={handleDownloadPDF}
                        >
                          {t("Download free white paper")}
                        </button>
                      </div>
                    ) : (
                      <>
                        <p className="d-block">
                          <Trans t={t}>
                            Discover everything you need to know about your
                            digital footprint with our white paper: a practical
                            guide containing an inventory of threats, a
                            diagnostic method and the golden rules of
                            remediation.
                          </Trans>
                        </p>
                        <WhitePaperForm setSent={() => setSent(true)} />
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
};

export default WhitePaper;
